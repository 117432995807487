<template>
<div>
    <v-dialog v-model="dialogRSPI" max-width="1200px" persistent scrollable>
        <v-card>
          <v-card-title class="headline pt-5">
            Create RSPI
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
          <v-card-text style="max-height: 900px">
          <v-form @submit.prevent ref="RSPIFormRef">
          <v-container>
            <v-row wrap align="center" no-gutters>
              <v-col cols="12" md="12">
                    <v-menu
                    dense
                    ref="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="formData.Date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.Date"
                        :rules="[formRules.required]"
                        label="Date"
                        readonly
                        outlined
                        color="header"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.Date"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="header" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="header"
                        @click="$refs.menu1.save(formData.Date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col cols="12" md="12">
                  <v-textarea
                    dense
                    outlined
                    color="header"
                    v-model="formData.Description"
                    :rules="[formRules.required]"
                    label="Description"
                  ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="formData.FundID"
                      label="Fund Code"
                      :rules="[formRules.required]"
                      dense
                      outlined
                      hide-details
                      color="header"
                      @change="getICSList(formData.FundID)"
                      :items="fundCodes"
                      item-text="FundCode"
                      item-value="FundID"
                      type="text"
                    ></v-autocomplete>
                  </v-col>
              <v-col cols="12">
                <v-data-table
                    v-model="selectedItems"
                    :headers="headers"
                    :items="itemList"
                    item-key="ICSID"
                    class="elevation-1 mt-7"
                    :loading="loading"
                    :search="searchText"
                    show-select
                >
                <template v-slot:top>
                  <v-toolbar flat>
                    <strong>List of ICS</strong>
                    <v-spacer></v-spacer>
                    <v-text-field
                      dense
                      outlined
                      color="header"
                      append-icon="mdi-magnify"
                      v-model="searchText"
                      style="max-width: 250px;"
                      label="Search here..."
                    ></v-text-field>
                  </v-toolbar>
                </template>
                <!-- <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :ripple="false"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </template> -->
                <template v-slot:[`item.IssuedDate`]="{ item }">
                  <span class="text-no-wrap">{{ formatDate(item.IssuedDate)}}</span>
                </template>
                <template v-slot:[`item.Action`]="{ item }">
                  <v-btn color="header" x-small outlined @click="viewICS(item)">View</v-btn>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="closeDialog()">Cancel</v-btn>
            <v-btn color="header" v-if="rspiData.edit" :loading="rspiLoading" class="white--text" :disabled="selectedItems.length == 0" @click="updateRSPI()"><v-icon>mdi-pencil-box</v-icon> Update</v-btn>
            <v-btn color="header" v-else :loading="rspiLoading" class="white--text" :disabled="selectedItems.length == 0" @click="submitRSPI()"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
          </v-card-actions>
        </v-card>
        <!-- <v-card>
          <v-card-title class="headline pt-5">Requisition and Issuance Slip</v-card-title>
          <v-card-text>
            <h2 align="center" class="ma-5">Coming Soon...</h2>
          </v-card-text>
        </v-card> -->
      </v-dialog>
      <v-dialog v-model="dialogConfirmSubmit" scrollable :max-width="showItems ? '1000px' : '500px'">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <v-card-text style="max-height: 900px">
            <div>
              Are you sure you want to 
              <span v-if="rspiData.edit">update</span>
              <span v-else>submit</span>
               this Report of Supplies and Materials Issued?
            </div>
            <div v-if="showItems">
                <v-data-table
                    :headers="headers2"
                    :items="selectedItems"
                    item-key="ICSID"
                    class="elevation-1 mt-4"
                >
                <template v-slot:top>
                  <v-toolbar flat>
                    <strong>List of Items Selected</strong>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) || 0.00 }}</span>
                </template>
                <template v-slot:[`item.EditableQty`]="{ item }">
                  {{ formatQty(item.EditableQty) || 0 }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.EditableQty * item.UnitCost) || 0.00 }}</span>
                </template>
                </v-data-table>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="grey darken-2" dark @click="showItems = !showItems">
                <span v-if="!showItems"><v-icon>mdi-eye</v-icon> Show List</span>
                <span v-else><v-icon>mdi-eye-off</v-icon> Hide List</span>
                </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogConfirmSubmit = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" v-if="rspiData.edit" @click="confirmUpdateRSPI()" :loading="rspiLoading"><v-icon>mdi-pencil-box</v-icon> Update</v-btn>
              <v-btn color="green darken-2 white--text" v-else @click="confirmsubmitRSPI()" :loading="rspiLoading"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <ICSModal :icsData="icsData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
</div>
</template>
<script>
export default {
  components: {
    ICSModal: () => import("./ViewICS.vue"),
  },
  props: {
    rspiData: {},
  },
  data: () => ({
    searchText: '',
    dialogRSPI: false,
    formData: {
      Description: '',
      FundID: null,
    },
    rspiLoading: false,
    users: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "ICS Code",
        align: "start",
        sortable: false,
        value: "ICSCODE",
      },
      {
        text: "RIS Code",
        align: "start",
        sortable: false,
        value: "RISCODE",
      },
      {
        text: "Purpose",
        align: "start",
        sortable: false,
        value: "ICSPurpose",
      },
      // {
      //   text: "Fund Code",
      //   align: "center",
      //   sortable: false,
      //   value: "FundCode",
      // },
      {
        text: "Date Issued",
        value: "IssuedDate",
        align: "center",
        sortable: false,
      },
      { text: "Action", value: "Action", align: "center", sortable: false },
    ],
    headers2: [
      {
        text: "ICS Code",
        align: "start",
        sortable: false,
        value: "ICSCODE",
      },
      {
        text: "RIS Code",
        align: "start",
        sortable: false,
        value: "RISCODE",
      },
      {
        text: "Purpose",
        align: "start",
        sortable: false,
        value: "ICSPurpose",
      },
      {
        text: "Fund Code",
        align: "center",
        sortable: false,
        value: "FundCode",
      },
      {
        text: "Date Issued",
        value: "IssuedDate",
        align: "center",
        sortable: false,
      }
    ],
    itemList: [],
    selectedItems: [],
    loading: false,
    dialogConfirmSubmit: false,
    showItems: false,
    fundCodes: [],
    icsData: [],
  }),
  watch: {
    searchText: {
      handler() {
      },
      deep: true,
    },
    rspiData: {
      handler(data) {
        if (data.RSPIID) {
            if(data.edit){
                this.formData.FundID = data.FundID
                this.formData.Description = data.Description
                this.formData.Date = data.Date.split(' ')[0];
                this.selectedItems = data.items;
                this.getICSList(parseInt(this.formData.FundID))
            }
        }
        this.dialogRSPI = true;
      },
      deep: true,
    },
  },
  mounted() {
    this.getFundCodes();
    this.eventHub.$on("closeViewRSPI", () => {
      this.getICSList(this.formData.FundID);
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewRSPI");
  },
  methods: {
    closeDialog(){
      this.dialogRSPI = false;
      this.dialogConfirmSubmit = false;
      this.formData.Description = null;
      this.formData.FundID = null;
      this.formData.Date = null;
      this.selectedItems = [];
      this.itemList = [];
      this.showItems = false;
      this.eventHub.$emit("closeCreateRSPI", false);
    },
    viewICS(item) {
      this.icsData = item;
    },
    submitRSPI() {
      if (this.$refs.RSPIFormRef.validate() && this.selectedItems.length > 0) {
        let error = false;
        for (let list of this.selectedItems) {
          if (parseFloat(list.EditableQty) > parseFloat(list.AllowableQty) || parseFloat(list.EditableQty) <= 0) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
        } else {
            this.dialogConfirmSubmit = true;
        }
      }
      else{
        this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
      }
    },
    confirmsubmitRSPI(){
      if(this.$refs.RSPIFormRef.validate() && this.selectedItems.length > 0){
      this.rspiLoading = true;
      let data = new FormData();
        data.append("FundID", this.formData.FundID);
        data.append("Description", this.formData.Description);
        data.append("Date", this.formData.Date);
        data.append("List", JSON.stringify(this.selectedItems));
        this.axiosCall("/rspi/add", "POST", data).then((res) => {
        if(res.data.status){
          window.open(this.apiUrl + "/rspi/report/" + res.data.data.RSPIID);
          this.closeDialog();
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
        this.rspiLoading = false;
        });
      }
    },
    updateRSPI() {
      if (this.$refs.RSPIFormRef.validate() && this.selectedItems.length > 0) {
        let error = false;
        for (let list of this.selectedItems) {
          if (parseFloat(list.EditableQty) > parseFloat(list.AllowableQty) || parseFloat(list.EditableQty) <= 0) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
        } else {
            this.dialogConfirmSubmit = true;
        }
      }
      else{
        this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
      }
    },
    confirmUpdateRSPI(){
      if(this.$refs.RSPIFormRef.validate()){
      this.rspiLoading = true;
      let data = new FormData();
        data.append("RSPIID", this.rspiData.RSPIID);
        data.append("FundID", this.formData.FundID);
        data.append("Description", this.formData.Description);
        data.append("Date", this.formData.Date);
        data.append("List", JSON.stringify(this.selectedItems));
        this.axiosCall("/rspi/edit", "POST", data).then((res) => {
        if(res.data.status){
          this.closeDialog();
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "RSPI was successfully updated";
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
        this.rspiLoading = false;
        });
      }
    },
    getICSList(FundID) {
      this.itemList = [];
      this.loading = true;
      let data = new FormData();
      data.append("FundID", FundID);
      this.axiosCall("/ics/getICSForRSPI", "POST", data).then((res) => {
        this.itemList = res.data.data;
        if(this.rspiData.edit){
          this.itemList = [...res.data.data,...this.rspiData.items];
        }
        this.loading = false;
      });
    },
    getFundCodes() {
      this.axiosCall("/get/getFundCodes", "GET").then((res) => {
        this.fundCodes = res.data.data;
      });
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>